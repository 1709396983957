import React, { useEffect, useState } from 'react'
import { Footer, Header, Sidebar } from 'src/components/organisms'
import './index.scss'
import { useLocation } from 'react-router-dom'
import { LABEL } from 'src/constants'

interface ILayoutProps {
  children: React.ReactNode
}

export const Layout = ({ children }: ILayoutProps): JSX.Element => {
  const location = useLocation()
  const [showSidebar, setShowSidebar] = useState(true)

  useEffect(() => {
    if (location?.pathname) {
      let splittedPaths = location?.pathname?.split('/')
      let isProject = splittedPaths[splittedPaths.length - 1] === LABEL.PROJECTS.toLowerCase()
      setShowSidebar(!isProject)
    }
  }, [location?.pathname])

  return (
    <div className="layout-container">
      <Header />
      <div className="layout-body">
        {showSidebar && (
          <div className="layout-left-section">
            <Sidebar />
          </div>
        )}
        <div className="layout-right-section">{children}</div>
      </div>
      <Footer />
    </div>
  )
}
