import { LABEL } from 'src/constants/index'

export const CACHE_CONFIG = {
  cacheTime: 3600000,
  staleTime: 3600000,
  refetchOnWindowFocus: false,
}

export const PROJECT_TRACKING_ACTIONS = {
  enable: 'Enable',
  disable: 'Disable',
}

export const statusMapInterceptTable: Record<string, string> = {
  [LABEL.INACTIVE]: LABEL.INACTIVE.toLowerCase(),
  [LABEL.ACTIVE]: LABEL.ACTIVE.toLowerCase(),
}

export const typeMapInterceptTableStatusCol: Record<string, string> = {
  [LABEL.POPUP_INTERCEPT]: LABEL.POPUP.toUpperCase(),
  [LABEL.FEEDBACK_TAB]: LABEL.FEEDBACK.toUpperCase(),
  [LABEL.EXIT_SURVEY]: LABEL.EXIT.toUpperCase(),
}
