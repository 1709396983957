// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading_circularProgress__ezY1G {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.4;
  z-index: 10;
  background: #fff;
  height: 100%;
}

.loading_loadingContainer__Ws6Ow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.loading_overlay__t2p-m {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.loading_relative__tRJCU {
  position: relative;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Loading/loading.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,OAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;AACF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AAEF;;AACA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,OAAA;EACA,SAAA;AAEF;;AACA;EACE,kBAAA;EACA,YAAA;AAEF","sourcesContent":[".circularProgress {\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  opacity: 0.4;\n  z-index: 10;\n  background: #fff;\n  height: 100%;\n}\n.loadingContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  z-index: 100;\n}\n\n.overlay {\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n}\n\n.relative {\n  position: relative;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circularProgress": `loading_circularProgress__ezY1G`,
	"loadingContainer": `loading_loadingContainer__Ws6Ow`,
	"overlay": `loading_overlay__t2p-m`,
	"relative": `loading_relative__tRJCU`
};
export default ___CSS_LOADER_EXPORT___;
