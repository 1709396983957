export * from './snackbar'

export const COOKIE = {
  MIN_EXPIRY: 1,
  MAX_EXPIRY: 365,
  DEFAULT_COOKIE_TIME: 0,
}

export const LABEL = {
  COOKIE_EXPIRY: 'Cookies expiry',
  DATE_RANGES: 'Date ranges',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  OR: 'OR',
  SETTINGS: 'Settings',
  EXPIRE_IN_DAYS: 'Expire In Days',
  NAME: 'Name',
  SURVEY_COUNT: 'Survey count',
  PROJECT_COUNT: 'Project count',
  INTERCEPT_COUNT: 'Intercept count',
  WHATS_NEW: "What's new?",
  CHAT_NOW: 'Chat now',
  SURVEY_TYPE: 'Survey type',
  MATCHED_VISITORS_COUNT: 'Matched visitors count',
  QUALIFIED_VISITORS: 'Qualified visitors',
  ENGAGED_VISITORS: 'Engaged visitors',
  LAUNCHED_VISITORS_COUNT: 'Launched visitors count',
  LAST_MODIFIED: 'Last modified',
  STATUS: 'Status',
  ACTIONS: 'Actions',
  RULE: 'Rule',
  INTERCEPT_POSITION: 'Intercept position',
  CUSTOM_VARIABLES: 'Custom variables',
  MAPPING_TYPE: 'Mapping type',
  ID_OR_VALUE: 'ID/Value',
  SURVEY: 'Survey',
  RULE_GROUP: 'Rule group',
  ADD_RULE: 'Add rule',
  WHERE: 'Where',
  VARIABLE_NAME: 'Variable name',
  OPERAND: 'Operand',
  CONDITION: 'Condition',
  TITLE: 'Title',
  NEW_PROJECT: 'New project',
  CREATE_PROJECT: 'Create project',
  EDIT_PROJECT: 'Edit project',
  DELETE_PROJECT: 'Delete project',
  SAVE: 'Save',
  CREATE: 'Create',
  UPDATE: 'Update',
  DELETE: 'Delete',
  YES: 'Yes',
  NEW_INTERCEPT: 'New intercept',
  CREATE_INTERCEPT: 'Create intercept',
  EDIT_INTERCEPT: 'Edit intercept',
  DELETE_INTERCEPT: 'Delete intercept',
  EDIT_MAPPING: 'Edit mapping',
  DELETE_MAPPING: 'Delete mapping',
  PROJECT: 'Project',
  PROJECTS: 'Projects',
  URL: 'URL',
  IMPRESSIONS: 'Impressions',
  VISITOR_COUNT: 'Visitor count',
  WEBSITE_URL: 'Website Url',
  CANCEL: 'Cancel',
  TYPE: 'Type',
  ANALYTICS: 'Analytics',
  INTERCEPTS: 'Intercepts',
  CODE: 'Code',
  MINUTES: 'Minutes',
  SECONDS: 'Seconds',
  REMOVE_RULE: 'Remove rule',
  SAVE_CHANGES: 'Save changes',
  MESSAGE: 'Message',
  PATH: 'Path',
  METHOD: 'Method',
  QUERY_COUNT: 'Query count',
  RESPONSE_TIME: 'Response time',
  USER: 'User',
  CREATED_AT: 'Created at',
  AUTO_LAUNCH_SURVEY: 'Auto-Launch survey',
  INTERCEPT: 'Intercept',
  WORKSPACE: 'Workspace',
  BACKGROUND_COLOR: 'Background color',
  TEXT_COLOR: 'Text color',
  WIDGET_NAME: 'Widget name',
  LAUNCH_BUTTON_TEXT: 'Launch button text',
  HEADER_HEIGHT: 'Header height',
  WIDGET_HEIGHT: 'Widget height',
  WIDGET_WIDTH: 'Widget width',
  FEEDBACK_BUTTON_HEIGHT: 'Feedback button height',
  FEEDBACK_BUTTON_WIDTH: 'Feedback button width',
  WIDGET_DESCRIPTION: 'Widget description',
  UPLOAD_IMAGE: 'Upload image',
  FEEDBACK_TYPE: 'Feedback type',
  TRANSPARENT_BACKGROUND: 'Transparent background',
  FEEDBACK_BUTTON_TEXT: 'Feedback button text',
  UPLOAD_LOGO: 'Upload logo',
  WORKSPACE_NAME: 'Workspace name',
  LAUNCH_SURVEY: 'Launch survey',
  NO_CONTENT: 'No content available',
  PROJECT_URL: 'Project URL: ',
  COPY_TRACKING_CODE: 'Copy tracking code',
  INVALID_URL: 'Invalid URL',
  APPLY_FILTERS: 'Apply filters',
  ALLOW_MULTIPLE_RESPONSE: 'Allow multiple response',
  EDIT_DETAILS: 'Edit Details',
  DELETE_DETAILS: 'Delete Details',
  EDIT: 'Edit',
  POPUP_INTERCEPT: 'Pop-up intercept',
  FEEDBACK_TAB: 'Feedback tab',
  EXIT_SURVEY: 'Exit Survey',
  POPUP: 'Popup',
  FEEDBACK: 'Feedback',
  EXIT: 'Exit',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  UNTITLED: 'Untitled',

  // placeholders
  SELECT_SURVEY: 'Select survey',
  SELECT_RULE: 'Select rule',
  SELECT_OPERAND: 'Select operand',
  RULE_GROUP_NAME: 'Rule group Name',
  PLEASE_ENTER_VARIABLE_NAME: 'Please enter variable name',
  SELECT_CONDITION: 'Select condition',
  SELECT_LABEL: 'Select label',
  SELECT: 'Select',
  ENTER_URL: 'Enter Website URL',

  DELETE_PERFORMANCE_LOGS_CONFIRM: 'Are you sure you want to delete performance logs?',
  DELETE_ITEM_CONFIRM: 'Are you sure you want to delete this item?',
  DELETE_ERROR_LOGS_CONFIRM: 'Are you sure you want to delete error logs?',
  WELCOME_MESSAGE: 'Welcome to Intercept',
  WORKSPACE_SELECT_MESSAGE: 'Pick a Workspace from list to get started.',
  DELETE_PROJECT_CONFIRM: 'Are you sure you want to delete this project?',
  IP_RANGE_ERROR: 'End IP must be greater than or equal to Start IP',
  DELETE_INTERCEPT_CONFIRM: 'Are you sure you want to delete this intercept',
}

export const TAB_LABEL = {
  WORKSPACES: 'Workspaces',
  ERROR_LOGS: 'Error logs',
  PERFORMANCE_LOGS: 'Performance logs',
  ANALYTICS: 'Analytics',
  INTERCEPTS: 'Intercepts',
  CODE: 'Code',
  SETTINGS: 'Settings',
  RULE_GROUP: 'Rule group',
  WIDGET_SETTINGS: 'Widget settings',
  DATA_MAPPING: 'Data mapping',
  INTERCEPT_SETTINGS: 'Intercept settings',
}

export const TOAST_MESSAGE = {
  WIDGET_SUCCESS: 'Widget settings updated successfully',
}

export const WIDGET_SETTINGS_POSITION = [
  'TOP_LEFT',
  'TOP_CENTER',
  'TOP_RIGHT',
  'BOTTOM_LEFT',
  'BOTTOM_CENTER',
  'BOTTOM_RIGHT',
]

export const WIDGET_HEADER_THRESHOLD_VALUE = 55

export enum WidgetSettingsPositionEnum {
  TOP_LEFT = 'TOP_LEFT',
  TOP_CENTER = 'TOP_CENTER',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_CENTER = 'BOTTOM_CENTER',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  CENTER_LEFT = 'CENTER_LEFT',
  CENTER_CENTER = 'CENTER_CENTER',
  CENTER_RIGHT = 'CENTER_RIGHT',
}

export const Color = {
  ROYAL_BLUE: '#004693',
  VIVID: '#0095dc',
  GREY: '#545E6B',
  HOVER_BACKGROUND_WHITE_SMOKE: '#F5F5F5',
  CLICKABLE_BLUE: '#1B87E6',
  BASE_GRAY_COLOR: '#545e6b',
  WHITE: '#FFFFFF',
}

export const INTERCEPT_SETTING_TYPE = {
  DATE_RANGE: 'DATE_RANGE',
  COOKIES: 'COOKIES',
}

export enum ValidationMessages {
  REQUIRED = 'This field is required',
  INVALID_NUMBER = 'Please enter a valid number',
  INVALID_TIME = 'Please enter a valid time (mm.ss)',
  SECONDS_LIMIT = 'Seconds must be less than 60',
  IP_PARTS = 'IP address must have 4 parts',
  IP_PART_NUMBER = 'Each part of IP must be a number',
  IP_RANGE = 'Each part must be between 0 and 255',
}

export const validationConstants = {
  IP_RANGE_MAX_VALUE: 255,
  IP_RANGE_MIN_VALUE: 0,
  IP_PART_LENGTH: 3,
  IP_PARTS: 4,
  DOT: '.',
  DEFAULT_MIN_LENGTH: 2,
  DEFAULT_MAX_LENGTH: 60,
  PAGE_URL_MAX_LENGTH: 255,
  PAGE_VISITED_MIN_LENGTH: 1,
  DEFAULT_ICON_SIZE: '16px',
  DEFAULT_PROJECT_COOKIE_EXPIRY_IN_DAYS: 365,
}

export const paginationConstants = {
  INITIAL_OFFSET: 1,
  PAGE_SIZE: 100,
}

export const toolTipConstants = {
  AUTO_LAUNCH_SURVEY:
    'Displays the survey automatically when conditions are met, skipping manual launch.',
  PROJECT_COOKIE_SETTINGS:
    'Enter a value between 1 and 365 days. If left blank, the system will default to 1 year',
  COPY_CODE: 'Copy code snippet',
}

export const PATH_NAME = {
  FORWARD_SLASH: '/',
  PERFORMANCE_LOGS: '/performance-logs',
  ERROR_LOGS: '/error-logs',
  HOME: '/home',
  CODE: '/code',
  ANALYTICS: '/analytics',
  SETTINGS: '/settings',
  INTERCEPTS: '/intercepts',
}

export const PLACEHOLDERS = {
  WIDGET_NAME: 'Ex. My Popup',
  LAUNCH_BUTTON: 'Ex. My Button',
  HEADER_HEIGHT: 'Ex. 35',
  WIDGET_HEIGHT: 'Ex. 400',
  WIDGET_WIDTH: 'Ex. 450',
  FEEDBACK_BUTTON_HEIGHT: 'Ex. 30',
  FEEDBACK_BUTTON_WIDTH: 'Ex. 30',
  FEEDBACK_BUTTON_TEXT: 'Ex. My Popup',
  PROJECT_COOKIES: 'Ex. 2',
}

export const MODAL_CONSTANTS = {
  DELETE_ERROR_LOGS: 'Delete error logs',
  DELETE_CONFIRM_ERROR_LOGS: 'Are you sure you want to delete error logs',
  DELETE_PERFORMANCE_LOGS: 'Delete performance Logs',
  DELETE_CONFIRM_PERFORMANCE_LOGS: 'Are you sure you want to delete error logs',
}

export const LOCAL_STORAGE_KEY = {
  PROJECT_URL: 'projectUrl',
}
