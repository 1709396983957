import React, { useContext, useMemo } from 'react'
import Cookies from 'js-cookie'
import { WuAppHeader } from '@questionproext/wick-ui-lib'
import { CACHE_CONFIG } from 'src/constants/common'
import { BreadCrumbContext, IBreadCrumbType } from 'src/context/BreadCrumbContext'
import { useGetHeaderFooterUserInfoQuery } from 'src/gql/queries/generated/getHeaderFooterUserInfo.generated'
import { useGetProductSwitcherQuery } from 'src/gql/queries/generated/getProductSwitchers.generated'

interface IBreadcrumb {
  key: string
  type: 'link' | 'labelOnly'
  label: string
  url?: string
  onClick?: () => void
}

const Header: React.FC = () => {
  const { data: headerFooterUserInfo } = useGetHeaderFooterUserInfoQuery({}, CACHE_CONFIG)
  const { data: productSwitcherData } = useGetProductSwitcherQuery({}, CACHE_CONFIG)
  const { breadCrumbState, removeBreadCrumb, resetBreadCrumbState } = useContext(BreadCrumbContext)

  const handleLogout = (): void => {
    Object.keys(Cookies.get()).forEach(function (cookieName) {
      Cookies.remove(cookieName)
    })
    localStorage.clear()
    window.location.assign('https://www.questionpro.com/a/logout.do')
  }

  const productSwitcherList = useMemo(
    () => productSwitcherData?.getProductSwitcher || [],
    [productSwitcherData]
  )
  const headerData = useMemo(
    () => headerFooterUserInfo?.getHeaderFooterUserInfo || null,
    [headerFooterUserInfo]
  )

  const breadcrumbs: IBreadcrumb[] = useMemo(
    () => [
      {
        key: 'home',
        type: 'link', // Ensure type is exactly "link"
        label: 'Home',
        url: '/',
        onClick: resetBreadCrumbState,
      },
      ...(breadCrumbState || []).map((crumb: IBreadCrumbType, index: number) => ({
        key: crumb.name + index,
        type: crumb.link ? ('link' as const) : ('labelOnly' as const),
        label: crumb.name,
        url: crumb.link,
        onClick: crumb.link ? ((() => removeBreadCrumb(crumb.link)) as () => void) : undefined,
      })),
    ],
    [breadCrumbState, removeBreadCrumb, resetBreadCrumbState]
  )

  return (
    headerData &&
    productSwitcherList && (
      //eslint-disable-next-line
      <span role="header-component">
        <WuAppHeader
          activeProductName="Intercept"
          breadcrumbs={breadcrumbs}
          myAccount={{
            invoice: { ...headerData?.myAccount?.invoice },
            issueTrackerCount: headerData?.myAccount?.issueTrackerCount,
            license: { ...headerData?.myAccount?.license },
            profile: { ...headerData?.myAccount?.profile },
            settings: headerData?.myAccount?.settings,
            usage: { ...headerData?.myAccount?.usage },
          }}
          onLogoutClick={handleLogout}
          productCategories={productSwitcherList}
        />
      </span>
    )
  )
}

export default Header
