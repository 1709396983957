export enum snackbarEnum {
  error = 'error',
  success = 'success',
}

export type ISnackbarType = {
  isOpen: boolean
  message: string
  type: snackbarEnum
}

export const initialSnackbarData = {
  isOpen: false,
  message: '',
  type: snackbarEnum.success,
}

export const snackbarHelper = {
  open: true,
  successType: snackbarEnum.success,
  errorType: snackbarEnum.error,
}

export const snackbarMessages = {
  interceptWasSuccessfullyUpdated: 'Your Intercept was successfully updated',
  interceptWasSuccessfullyCreated: 'Your Intercept was successfully created',
  interceptWasSuccessfullyDeleted: 'Your Intercept was successfully deleted',

  projectWasSuccessfullyDeleted: 'Your project was successfully deleted',
  projectWasSuccessfullyCreated: 'Your project was successfully created',
  projectWasSuccessfullyUpdated: 'Your project was successfully updated',

  ruleWasSuccessfullyUpdated: 'Your rule was successfully updated',
  ruleWasSuccessfullyCreated: 'Your rule was successfully created',

  ruleGroupWasSuccessfullyUpdated: 'Your rule group was successfully updated',
  ruleGroupWasSuccessfullyCreated: 'Your rule group was successfully created',

  pageUrlIsNotValid: 'Page url is not valid',
  trackingCodeCopied: 'Tracking code copied successfully!',

  noSurveyInWorkspace:
    'No surveys are available in the workspace. Please add a survey in CX-Core before creating an intercept.',

  projectSettingsUpdated: 'Your project settings saved successfully',

  trackingCodeIsDisabled: 'Code tracking has been disabled.',
  trackingCodeIsEnabled: 'Code tracking is now active.',

  interceptSettingsSaved: 'Intercept settings saved successfully',
}
