import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WORKSPACES_ROUTE } from 'src/constants/routes'
import { LABEL } from 'src/constants'
import { IBreadcrumb } from 'src/gql/types'
import { BreadcrumbType } from 'src/constants/enum'

interface IBreadcrumbState {
  items: IBreadcrumb[]
}

const initialState: IBreadcrumbState = {
  items: [
    {
      key: 1,
      type: BreadcrumbType.LINK,
      label: LABEL.WORKSPACE,
      url: WORKSPACES_ROUTE,
    },
  ],
}

export const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<IBreadcrumb[]>) => {
      const hasWorkspace = action.payload.some((item) => item.url === WORKSPACES_ROUTE)
      state.items = hasWorkspace ? action.payload : [initialState.items[0], ...action.payload]
    },

    addBreadcrumb: (state, action: PayloadAction<IBreadcrumb>) => {
      const existingIndex = state.items.findIndex((item) => item.key === action.payload.key)

      if (existingIndex !== -1) {
        state.items = state.items.slice(0, existingIndex + 1)
        state.items[existingIndex] = {
          ...action.payload,
          key: existingIndex + 1,
        }
      } else {
        state.items.push({
          ...action.payload,
          key: state.items.length + 1,
        })
      }
    },

    removeFromIndex: (state, action: PayloadAction<number>) => {
      state.items = state.items.slice(0, action.payload + 1)

      state.items = state.items.map((item, index) => ({
        ...item,
        key: index + 1,
      }))
    },

    initializeBreadcrumbs: (state) => {
      state.items = initialState.items
    },
  },
})

export const { setBreadcrumbs, addBreadcrumb, removeFromIndex, initializeBreadcrumbs } =
  breadcrumbSlice.actions

export default breadcrumbSlice.reducer

export const selectBreadcrumbs = (state: { breadcrumb: IBreadcrumbState }): IBreadcrumb[] =>
  state.breadcrumb.items
