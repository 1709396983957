import { configureStore } from '@reduxjs/toolkit'
import breadcrumbReducer from './features/breadcrumbSlice'
import sidebarReducer from './features/sidebarSlice'

export const store = configureStore({
  reducer: {
    breadcrumb: breadcrumbReducer,
    sidebar: sidebarReducer,
  },
})

export type IRootState = ReturnType<typeof store.getState>
export type IAppDispatch = typeof store.dispatch
