import React, { useMemo } from 'react'
import Cookies from 'js-cookie'
import { WuAppHeader } from '@questionproext/wick-ui-lib'
import { CACHE_CONFIG } from 'src/constants/common'
import { useGetHeaderFooterUserInfoQuery } from 'src/gql/queries/generated/getHeaderFooterUserInfo.generated'
import { useGetProductSwitcherQuery } from 'src/gql/queries/generated/getProductSwitchers.generated'
import { LABEL } from 'src/constants'
import { useNavigate } from 'react-router-dom'
import { IBreadcrumb } from 'src/gql/types'
import { selectBreadcrumbs, removeFromIndex } from 'src/store/features'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { BreadcrumbType } from 'src/constants/enum'

export const Header: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const reduxBreadcrumbs = useAppSelector(selectBreadcrumbs)

  const { data: headerFooterUserInfo } = useGetHeaderFooterUserInfoQuery({}, CACHE_CONFIG)
  const { data: productSwitcherData } = useGetProductSwitcherQuery({}, CACHE_CONFIG)

  const executeLogout = (): void => {
    Object.keys(Cookies.get()).forEach(function (cookieName) {
      Cookies.remove(cookieName)
    })
    localStorage.clear()
    window.location.assign('https://www.questionpro.com/a/logout.do')
  }

  const getProductSwitcherList = useMemo(
    () => productSwitcherData?.getProductSwitcher || [],
    [productSwitcherData]
  )

  const getHeaderData = useMemo(
    () => headerFooterUserInfo?.getHeaderFooterUserInfo || null,
    [headerFooterUserInfo]
  )

  const getBreadcrumbs = useMemo(() => {
    if (!reduxBreadcrumbs?.length) return []

    return reduxBreadcrumbs.map((crumb: IBreadcrumb, index) => ({
      key: crumb.key,
      type: index === reduxBreadcrumbs.length - 1 ? BreadcrumbType.LABEL_ONLY : crumb.type,
      label: crumb.label,
      url: crumb.url,
      onClick:
        index === reduxBreadcrumbs.length - 1
          ? undefined
          : (event: React.MouseEvent<HTMLAnchorElement>): void => {
              event.preventDefault()
              navigate(`${crumb.url}`, {
                state: {
                  name: crumb.label,
                  link: `${crumb.url}`,
                  breadCrumbList: reduxBreadcrumbs,
                },
              })
              dispatch(removeFromIndex(index))
            },
    }))
  }, [reduxBreadcrumbs, navigate, dispatch])

  return (
    getHeaderData &&
    getProductSwitcherList && (
      <span
        //eslint-disable-next-line
        role="header-component"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 3,
        }}
      >
        <WuAppHeader
          activeProductName={LABEL.INTERCEPT}
          breadcrumbs={getBreadcrumbs}
          myAccount={{
            invoice: { ...getHeaderData?.myAccount?.invoice },
            issueTrackerCount: getHeaderData?.myAccount?.issueTrackerCount,
            license: { ...getHeaderData?.myAccount?.license },
            profile: { ...getHeaderData?.myAccount?.profile },
            settings: getHeaderData?.myAccount?.settings,
            usage: { ...getHeaderData?.myAccount?.usage },
          }}
          onLogoutClick={executeLogout}
          productCategories={getProductSwitcherList}
        />
      </span>
    )
  )
}