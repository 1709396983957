import React, { FC, memo } from 'react'
import { WuSpinner } from '@questionproext/wick-ui-lib'
import { Color } from 'src/constants'
import './index.scss'

interface ILoadingProps {
  isOpen?: boolean
  fullScreen?: boolean
}

const Loading: FC<ILoadingProps> = ({ isOpen = true, fullScreen = false }) => {
  if (!isOpen) return null

  return (
    <div
      // eslint-disable-next-line
      role="wu-spinner"
      className={fullScreen ? 'loading-container full-screen' : 'loading-container inline'}
    >
      <WuSpinner color={Color.ROYAL_BLUE} size="small" />
    </div>
  )
}

export default memo(Loading)
