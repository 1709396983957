import { FC, useContext, useEffect } from 'react'
import { Alert, Snackbar } from '@mui/material'
import { SnackbarContext } from 'src/context/SnackbarContext'

const SnackbarItem: FC = () => {
  const {
    snackbarState: { isOpen, type, message },
    setSnackbarState,
  } = useContext(SnackbarContext)

  useEffect(() => {
    if (isOpen) {
      setTimeout(
        () =>
          setSnackbarState({
            isOpen: false,
          }),
        3000
      )
    }
  }, [isOpen, setSnackbarState])

  return !!type ? (
    <Snackbar
      open={isOpen}
      className="snackbarRoot"
      data-testid="snackbar"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert severity={type} sx={{ width: '350px' }} variant="standard" data-testid="message">
        {message}
      </Alert>
    </Snackbar>
  ) : null
}

export default SnackbarItem
