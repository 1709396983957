import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IRootState } from 'src/store'
import { ISideBarMenuItem, ISidebarState } from 'src/gql/types'

const initialState: ISidebarState = {
  activeTabId: 1,
  menuItems: [],
  isExpanded: false,
}

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTabId = action.payload
    },
    setMenuItems: (state, action: PayloadAction<ISideBarMenuItem[]>) => {
      state.menuItems = action.payload
    },
    setIsExpanded: (state, action: PayloadAction<boolean>) => {
      state.isExpanded = action.payload
    },
  },
})

export const { setActiveTab, setMenuItems, setIsExpanded } = sidebarSlice.actions
export const selectSidebar = (state: IRootState): ISidebarState => state.sidebar
export default sidebarSlice.reducer
