export enum BreadcrumbType {
  LINK = 'link',
  LABEL_ONLY = 'labelOnly',
}

export enum BreadcrumbPositionEnum {
  WORKSPACES = 1,
  PROJECTS = 2,
  PROJECT = 3,
  INTERCEPT = 4,
}

export enum WorkspaceSideTabPositionEnum {
  WORKSPACES = 1,
  ERROR_LOGS = 2,
  PERFORMANCE_LOGS = 3,
}

export enum InterceptSideTabPositionEnum {
  HOME = 1,
  TRACKING_CODE = 2,
  ANALYTICS = 3,
  SETTINGS = 4,
}

export enum InterceptHeaderTabPositionEnum {
  RULE_GROUP = 1,
  WIDGET_SETTINGS = 2,
  DATA_MAPPING = 3,
  INTERCEPT_SETTINGS = 4,
}

export enum InputTypeEnum {
  TEXT = 'text',
  NUMBER = 'number',
  TIME = 'time',
  IP = 'ip',
}

export enum InterceptSettingTypeEnum {
  DATE_RANGE = 'DATE_RANGE',
  COOKIES = 'COOKIES',
  ALLOW_MULTIPLE_RESPONSE = 'ALLOW_MULTIPLE_RESPONSE',
}

export enum SortOrderEnum {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}
