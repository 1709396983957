import axios, { AxiosRequestHeaders } from 'axios'
import { useContext } from 'react'
import { SnackbarContext } from 'src/context/SnackbarContext'
import { REACT_APP_BASE_URL } from 'src/constants/api'
import { snackbarHelper } from 'src/constants/snackbar'

export const apiClient = axios.create({ baseURL: REACT_APP_BASE_URL })

export const axiosRequest = <Tdata, Tvariables>(
  query: string,
  headers?: AxiosRequestHeaders
): ((variables?: Tvariables) => Promise<Tdata>) => {
  // eslint-disable-next-line
  const { setSnackbarState } = useContext(SnackbarContext)
  return async (variables?: Tvariables) => {
    try {
      const { data: res } = await apiClient.post<{ data: Tdata; errors: any }>(
        '',
        { query, variables },
        { headers: headers }
      )

      if (res.errors && res.errors.length > 0) {
        throw new Error(res.errors[0].message)
      }

      return res.data
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errors?.[0]?.message || error.message
      setSnackbarState({
        message: errorMessage,
        type: snackbarHelper.errorType,
        isOpen: snackbarHelper.open,
      })
      throw error
    }
  }
}
