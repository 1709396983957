import React, { useMemo } from 'react'
import { LABEL } from 'src/constants/index'
import { CACHE_CONFIG } from 'src/constants/common'
import { useGetHeaderFooterUserInfoQuery } from 'src/gql/queries/generated/getHeaderFooterUserInfo.generated'
import { WuIconButton } from '@questionproext/wick-ui-lib'
import { WuIcon, WuIconTypeEnum } from '@questionproext/wick-ui-icons'

export const Footer: React.FC = () => {
  const { data } = useGetHeaderFooterUserInfoQuery({}, CACHE_CONFIG)
  const footerData = useMemo(() => data?.getHeaderFooterUserInfo?.footer || null, [data])

  return (
    data?.getHeaderFooterUserInfo?.footer && (
      <footer className="footer">
        <div className="container">
          <div className="left">
            <div className="partners" data-testid="partner-license">
              {footerData?.licenseName}
            </div>
            <div className="copyright">
              <span>
                &#169;{new Date().getFullYear()} {footerData?.appName}
              </span>
            </div>
          </div>
          <div className="right">
            <a target="_blank" href="https://www.questionpro.com/updates/" rel="noreferrer">
            <WuIconButton tooltip={`${LABEL.WHATS_NEW}`}>
                <WuIcon
                  aria-label="wu-icon-whats-new"
                  size={'18px'}
                  iconType={WuIconTypeEnum.OLD_WHATS_NEW}
                />
              </WuIconButton>
            </a>
            <a
              target="_blank"
              href="https://www.questionpro.com/chat/?mode=launch&pageMode=quickChat"
              rel="noreferrer"
            >
              <WuIconButton tooltip={`${LABEL.CHAT_NOW}`}>
                <WuIcon
                  aria-label="wu-icon-live-chat"
                  size={'18px'}
                  iconType={WuIconTypeEnum.OLD_LIVE_CHAT}
                />
              </WuIconButton>
            </a>
          </div>
        </div>
      </footer>
    )
  )
}

