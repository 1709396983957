import { FC } from 'react'
import { WuIcon, WuIconSizeEnum } from '@questionproext/wick-ui-icons'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { selectSidebar, setActiveTab, setIsExpanded } from 'src/store/features'
import { ISideBarMenuItem } from 'src/gql/types'
import './index.scss'
import { Color } from 'src/constants'

export const Sidebar: FC = () => {
  const dispatch = useAppDispatch()
  const { menuItems, activeTabId, isExpanded } = useAppSelector(selectSidebar)

  const handleNavigate = (navItem: ISideBarMenuItem): void => {
    dispatch(setActiveTab(navItem.id))
  }

  const handleMouseEnter = (): void => {
    dispatch(setIsExpanded(true))
  }

  const handleMouseLeave = (): void => {
    dispatch(setIsExpanded(false))
  }

  return (
    <div
      className={`sidebar ${isExpanded ? 'expanded' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {menuItems?.map((navItem) => (
        <div
          key={navItem.id}
          className={`sidebar-item ${activeTabId === navItem.id ? 'active' : ''}`}
          onClick={() => handleNavigate(navItem)}
        >
          <WuIcon
            role={`wu-sidebar-icon-${navItem.id}`}
            size={WuIconSizeEnum.SM}
            iconType={navItem.icon}
            color={activeTabId === navItem.id ? Color.CLICKABLE_BLUE   : Color.BASE_GRAY_COLOR}
          />

          <span className={`label-container ${activeTabId === navItem.id ? 'active' : ''}`}>
            {navItem?.label}
          </span>
        </div>
      ))}
    </div>
  )
}
